import { initPhoneMaskAndValidatePhone } from './components/initPhoneMaskAndValidatePhone';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initTermsCheckbox } from './components/trial/initTermsCheckbox';
import { initReloadFunnelState } from './components/initReloadFunnelState';
import { initDropdown } from './components/initDropdown';
import { initHamburger } from './components/initHumburger';
import { initMobileMenu } from './components/initMobileMenu';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initScrollLocker } from './assets/initScrollLocker';
import { initForms } from './components/initForms';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initRedirectToCheckout } from '../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initJivosite } from './components/initJivosite';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', handleOnDOMContentLoaded, false);
window.addEventListener('pageshow', handlePageShow);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handlePageShow() {
    initReloadFunnelState();
}

function handleOnDOMContentLoaded() {
    initPageLoader();
    window.pageLoader.hide();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initLazyLoadFonts();
    initHashEmail();
    initScrollLocker();
    initPhoneMaskAndValidatePhone();
    initTermsCheckbox();
    initDropdown();
    initHamburger();
    initMobileMenu();
    initForms();
    initScrollToAnchor();
    initJivosite();
    initMixpannel();
    initRedirectToCheckout();
}

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});
