export function initHamburger() {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const languageBtn = document.querySelector('[data-mobile-only]');
    const activeClass = 'hamburger--active';

    // LISTENERS
    hamburger?.addEventListener('click', handleOnClickHamburger, false);

    // HANDLERS
    function handleOnClickHamburger() {
        hamburger.classList.toggle(activeClass);

        hamburger.classList.contains(activeClass)
            ? (languageBtn.style.display = 'block')
            : (languageBtn.style.display = 'none');
    }
}
