import { initAnimationOnSearchPhone } from './initAnimationOnSearchPhone';
import { initServerFetchNumValidation } from './initServerFetchNumValidation';

export const initPhoneMaskAndValidatePhone = () => {
    // VARS
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;
    const urlParams = new URLSearchParams(window.location.search);
    const affParam = urlParams.get('aff_parameter');
    const affId = urlParams.get('aff_id');
    const phoneNumberFromUrl = urlParams.get('phoneNumber');
    const hiddenTelInput = document.querySelector('[data-phone-number-input-value]');
    const locationContainer = document.querySelector('[data-location]');
    const pageTrialAffId = document.querySelector('[data-page-trial-aff]');
    let animationStarted = false;
    const validationAffiliateAnimation =
        affParam === 'animation' && affId === 'ho_2444' && phoneNumberFromUrl && !animationStarted;

    // LISTENERS
    locationForms.forEach((form, index) => {
        if (validationAffiliateAnimation) {
            if (!pageTrialAffId) {
                return;
            }
            initSearchAnimationWithPhone(phoneNumberFromUrl, index);
            animationStarted = true;
        }

        form.addEventListener('submit', function (event) {
            event.preventDefault();
            const input = this.querySelector('[data-tell-input]');
            const phoneNumber = window[`phoneMask_${index}`].getNumber();
            const replacedNumber = window[`phoneMask_${index}`].getNumber().replace(/[^0-9]/g, '');
            const isNumberHasZero = replacedNumber.match(/0{7,}/);

            if (isNumberHasZero) {
                form.classList.add('error');
                return;
            }

            hiddenTelInput.value = replacedNumber;
            locationContainer.dataset.location = window[`phoneMask_${index}`].defaultCountry;

            if (validatePhoneNumber(input, form, index)) {
                startAnimationButton(form);
                startAnimationMap(form, phoneNumber, replacedNumber, window[`phoneMask_${index}`]);
            } else {
                form.classList.add('error');
            }
        });
    });

    // FUNCTIONS
    function validatePhoneNumber(input, form, index) {
        const phoneLength = input.value.trim().length;
        const placeholderLength = input.placeholder.length;
        const country = window[`phoneMask_${index}`].defaultCountry;

        const countryConditions = {
            de: () => phoneLength === placeholderLength || phoneLength === placeholderLength - 1,
            my: () =>
                phoneLength === placeholderLength ||
                phoneLength === placeholderLength - 1 ||
                phoneLength === placeholderLength - 2,
            kh: () => phoneLength === 10 || phoneLength === 11,
            id: () => phoneLength === 13,
        };

        return countryConditions[country] ? countryConditions[country]() : phoneLength === placeholderLength;
    }

    function startAnimationMap(form, phoneNumber, replacedNumber, iti) {
        const popupPhoneNumber = document.querySelector('[data-popup-phone-number]');
        const locationContainer = document.querySelector('[data-location]');

        form.classList.remove('error');
        popupPhoneNumber.innerText = phoneNumber.trim();
        if (!locationContainer.dataset.location.length) {
            locationContainer.dataset.location = iti.defaultCountry;
        }
        initAnimationOnSearchPhone();
        disableScroll();
        initServerFetchNumValidation(replacedNumber);
    }

    function startAnimationButton(form) {
        const button = form.querySelector('[data-phone-number-form-submit]');
        button.classList.add('active-spinner');
        form.setAttribute('disabled', '');
    }

    function initSearchAnimationWithPhone(phoneNumber, index) {
        const form = document.querySelector('[data-phone-number-form]');
        const input = form.querySelector('[data-tell-input]');
        input.value = phoneNumber;
        const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        hiddenTelInput.value = cleanedPhoneNumber;

        const iti = window.intlTelInputGlobals;
        const countryData = iti.getCountryData();
        const detectedCountry = detectCountryByDialCode(cleanedPhoneNumber, countryData);

        if (detectedCountry) {
            locationContainer.dataset.location = detectedCountry.iso2;
        } else {
            form.classList.add('error');
        }

        startAnimationMap(form, phoneNumber, cleanedPhoneNumber, detectedCountry);
    }

    function detectCountryByDialCode(phoneNumber, countryData) {
        for (let i = 0; i < countryData.length; i++) {
            const { dialCode } = countryData[i];
            if (phoneNumber.startsWith(dialCode)) {
                return countryData[i];
            }
        }
        return null;
    }
};
