export const initReloadFunnelState = () => {
    var historyTraversal =
        event.persisted || (typeof window.performance != 'undefined' && window.performance.navigation.type === 2);

    if (historyTraversal) {
        hidePopupAnimation();
        stopAnimationButton();
    }
};

function hidePopupAnimation() {
    const wrapper = document.querySelector('.geofinder-wrapper');
    const popup = document.querySelector('[data-popup-location]');

    if (!wrapper || !popup) return;

    popup.classList.add('hidden');
    wrapper.classList.remove('disable-scroll');
    wrapper.removeAttribute('style');
    document.querySelector('[data-header]').classList.remove('popup-console');
    document.querySelector('[data-progress-line]').classList.remove('progress__line--animated');
    document.querySelector('[data-popup-success-block]').classList.remove('success');
    document.querySelector('[data-popup-location]').classList.remove('success');
    document.querySelector('[data-popup-phone-number]').value = '';
}

function stopAnimationButton() {
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;

    [...locationForms].forEach((form) => {
        const button = form.querySelector('[data-phone-number-form-submit]');
        button.classList.remove('active-spinner');
        form.removeAttribute('disabled');
    });
}
