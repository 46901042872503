import anime from 'animejs/lib/anime.es.js';

export const initDropdown = () => {
    // VARS
    const toggles = document.querySelectorAll('[data-accordion-toggle]');
    if (!toggles.length) return;
    let duration = 200;
    const activeClass = 'is-open-accordion';
    const accordions = document.querySelectorAll('[data-accordion]');

    // EVENTS
    toggles.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClick, false);
    });

    document.body.addEventListener('click', (event) => {
        const toggle = event.target.closest('[data-accordion-toggle]');

        let currentAccordion;

        if (toggle) {
            currentAccordion = toggle.closest('[data-accordion]');
        }

        accordions.forEach((accordion) => {
            if (accordion.classList.contains(activeClass) && (!accordion || accordion !== currentAccordion)) {
                closeAccordion(accordion);
            }
        });
    });

    // HANDLERS
    function handleOnClick() {
        const accordion = this.closest('[data-accordion]');
        accordion.classList.contains(activeClass) ? closeAccordion(accordion) : openAccordion(accordion);
        changeButtonText(this);
    }

    // FUNCTIONS
    function openAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');
        const height = body.scrollHeight;
        accordion.classList.add(activeClass);

        anime({
            targets: body,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                body.style.height = 'auto';
            },
        });
    }

    function closeAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');
        const height = body.scrollHeight;
        body.style.height = `${height}px`;
        accordion.classList.remove(activeClass);

        anime({
            targets: body,
            height: 0,
            easing: 'linear',
            duration: duration,
        });
    }

    function changeButtonText(toggle) {
        if (!toggle.hasAttribute('data-text-see')) return;

        const accordion = toggle.closest('[data-accordion]');
        const buttons = accordion.querySelectorAll('[data-accordion-toggle]');

        if (accordion.classList.contains(activeClass)) {
            buttons.forEach((button) => {
                button.textContent = button.dataset.textHide;
            });
        } else {
            buttons.forEach((button) => {
                button.textContent = button.dataset.textSee;
            });
        }
    }
};
