export const initTermsCheckbox = () => {
    let submitButtonTrial = document.querySelector('[data-trial-popup-btn]');
    let checkboxTrial = document.querySelector('[data-trial-popup-checkbox]');

    if (!submitButtonTrial && !checkboxTrial) {
        return;
    }

    checkboxTrial.checked = true;

    checkboxTrial.addEventListener('change', function () {
        submitButtonTrial.disabled = !checkboxTrial.checked;
    });
};
